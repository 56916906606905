@import "variables";

#root {
  height: 100vh;
  margin: auto;
}

body {
  min-height: 100%;
}

.layout {
  width: 100%;
}

.header {
  background-color: $success;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
}

.sidebar {
  background: #FFFFFF !important;
}

.content {
  min-height: 800px;
  padding: 20px;
}

.ant-layout-sider-trigger {
  background-color: $success !important;
}

.amplify-flex.amplify-tabs {
  display: none;
}